.mobile-sidebar-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 60px;
  right: -40px;
  background: var(--layout-bg);
  opacity: 0.2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
