/** flex-box **/
.flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }
.flex-row { flex-direction: row; }
.flex-col { flex-direction: column; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-col-reverse { flex-direction: column-reverse; }
.flex-center { justify-content: center; align-items: center; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.align-center { align-items: center; }
.align-end { align-items: flex-end; }
.align-stretch { align-items: stretch; }
.self-align-end { align-self: flex-end; }
.self-align-stretch { align-self: stretch; }
.flex-grow { flex: 1 0; }

/** primary **/
.relative { position: relative; }
.block { display: block; }
.block-center { margin: 0 auto; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.border-radius-0 { border-radius: 0; }
.nowrap { white-space: nowrap; }

.m-4 { margin: 4px; }
.m-5 { margin: 5px; }
.m-8 { margin: 8px; }
.mt-8 { margin-top: 8px; }
.mb-8 { margin-bottom: 8px; }
.ml-8 { margin-left: 8px; }
.mr-8 { margin-right: 8px; }
.m-16 { margin: 16px; }
.mt-16 { margin-top: 16px; }
.mb-16 { margin-bottom: 16px; }
.ml-16 { margin-left: 16px; }
.mr-16 { margin-right: 16px; }
.p-8 { padding: 8px; }
.pt-8 { padding-top: 8px; }
.pb-8 { padding-bottom: 8px; }
.pl-8 { padding-left: 8px; }
.pr-8 { padding-right: 8px; }
.p-16 { padding: 16px; }
.pt-16 { padding-top: 16px; }
.pb-16 { padding-bottom: 16px; }
.pl-16 { padding-left: 16px; }
.pr-16 { padding-right: 16px; }

.font-12 { font-size: 12px; }
.font-14 { font-size: 14px; }
.font-16 { font-size: 16px; }
.font-18 { font-size: 18px; }
.font-20 { font-size: 20px; }
.font-22 { font-size: 22px; }
.font-24 { font-size: 24px; }

.search-bar {
  padding: 20px;
  margin-bottom: 20px;
  background: #ecf0f1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-wrap: wrap;
}
