.layout-header {
  height: var(--layout-header-height);
  background: var(--layout-bg);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .m-menu-trigger {
    width: var(--layout-header-height);
    height: var(--layout-header-height);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-wrap {
    flex-shrink: 0;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      display: block;
      width: 35px;
      height: 35px;
    }

    .app-name {
      margin: 0 0 0 10px;
      font-size: 20px;
    }
  }

  .logo-wrap.m {
    width: auto;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    .logo {
      width: 20px;
      height: 20px;
    }

    .app-name {
      font-size: 18px;
    }
  }

  .layout-header-right-pc {
    flex-shrink: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  .layout-header-right-m {
    flex-shrink: 0;
    padding: 0 10px;
    font-size: 12px;
    line-height: 1.2;
    text-align: right;
    white-space: nowrap;

    .logout {
      margin-top: 4px;
      font-size: 14px;
      color: #18BC9C;
      span {
        margin-left: 2px;
      }
    }
  }
}
