.layout {
  background: var(--layout-bg);
}

.layout-sidebar {
  position: fixed;
  top: var(--layout-header-height);
  bottom: 0;
  left: 0;
  width: 220px;
  background: var(--layout-bg);
  overflow-y: auto;
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.layout-main {
  height: calc(100vh - var(--layout-header-height));
  box-sizing: border-box;
  background: #ffffff;
  overflow: auto;
}
